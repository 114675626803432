import type {
  ReferenceDetail,
  ReferencePagedResult,
  ReferenceCategoryPagedResult,
  ReferenceCategory,
  SlugTranslation,
} from '~/types/api'
import type { QueryParams } from '~/types/api'
import { APIFilters, getBaseFilter } from '~/utils/APIFilters'
import { NuxtLocale } from '~/constants/locales'
import { ApiLocale } from '~/constants/locales'

export function createReferencesRepository() {
  const { $api } = useNuxtApp()
  const { get } = baseRepository($api)

  const { $i18n } = useNuxtApp()
  const { locale } = $i18n

  const DOMAIN = 'references'
  const DOMAIN_TRANSLATE = 'translate'
  const DOMAIN_CATEGORIES = 'reference-categories'
  const LANG = 'references'
  const LANG_CATEGORIES = 'categories'

  async function getAll(params?: QueryParams) {
    if (!params) {
      params = {
        page: 1,
        itemsPerPage: 6,
        filter: APIFilters.makeFilter(getBaseFilter(locale.value, true)),
        sort: APIFilters.makeSort({ weight: 'ASC', title: 'ASC' }),
      }
    }
    return get<ReferencePagedResult>([DOMAIN], { params })
  }

  async function getAllPagination(
    page: number,
    itemsPerPage: number,
    categoryId?: number,
    lineId?: number
  ) {
    const filter = getBaseFilter(locale.value, true)
    if (categoryId) {
      filter.push({
        [APIFilterOP.EQUALS]: {
          'referenceCategories.translationOfNid': categoryId,
        },
      })
    }
    if (lineId) {
      filter.push({
        [APIFilterOP.EQUALS]: {
          'productLines.translationOfNid': lineId,
        },
      })
    }
    let sort = { weight: 'ASC', title: 'ASC' }
    if (lineId) {
      sort = { weight: 'ASC' }
    }

    const params = {
      page,
      itemsPerPage,
      filter: APIFilters.makeFilter(filter),
      sort: APIFilters.makeSort({ weight: 'ASC', nid: 'DESC' }),
    }
    return get<ReferencePagedResult>([DOMAIN], { params })
  }

  async function getMoreReferences(referenceNid: number) {
    const filter = getBaseFilter(locale.value, true)
    filter.push({
      [APIFilterOP.LOWER_THAN]: {
        nid: referenceNid,
      },
    })
    const params = {
      filter: APIFilters.makeFilter(filter),
      page: 1,
      itemsPerPage: 2,
    }
    return get<ReferencePagedResult>([DOMAIN], { params })
  }

  async function getByNid(nid: number) {
    return get<ReferenceDetail>([DOMAIN, nid])
  }

  async function getBySlug(slug: string) {
    const params = {
      language: convertNuxtLocale(locale.value) as NuxtLocale,
    }
    return get<ReferenceDetail>([DOMAIN, slug], { params })
  }

  async function getCategories() {
    const params = {
      filter: APIFilters.makeFilter(getBaseFilter(locale.value, true)),
    }
    return get<ReferenceCategoryPagedResult>([DOMAIN_CATEGORIES], {
      params,
    })
  }

  async function getCategory(nid: number) {
    return get<ReferenceCategory>([DOMAIN_CATEGORIES, nid])
  }

  async function translateSlug(slug: string, targetLanguage: ApiLocale) {
    return get<SlugTranslation>([DOMAIN, slug, DOMAIN_TRANSLATE], {
      params: {
        language: targetLanguage,
      },
    }) // TODO: langPath: ['base', LANG],
  }

  async function getAllCategories(params?: QueryParams) {
    return get<ReferenceCategoryPagedResult>([DOMAIN_CATEGORIES], { params })
  }

  return {
    getAll,
    getAllPagination,
    getMoreReferences,
    getByNid,
    getBySlug,
    getCategories,
    translateSlug,
    getAllCategories,
    getCategory,
  }
}
